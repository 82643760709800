.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 50px;
}

.about .top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.about .top .icon{
    width: 450px;
    height: 320px;
}

.content{
    margin-top: 20px;
    font-size: 20px;
    color: #000;
    font-weight: 300;
}