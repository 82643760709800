.items-view{
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.items-view .items-left{
    flex: 4;
    display:flex;
    height: 120px;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;

}

.items-view .items-left .items-left-time{
    color: #999;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.items-view .items-left .items-left-title{
    color: #333;
    font-size: 16px;
    font-weight: bold;
}

.items-view .items-left .items-left-des{
    color: #666;
    font-size: 15px;
}

.items-view .items-right{
    flex:1;
    margin-left: 20px;
}

.items-view .time-view{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.items-view .time-view .items-num{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
    font-size:13px;
}