.container{
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    min-height: 500px;
}


.container .mark{
    width: 100%;
}

.container img{
    width: 100%;

}